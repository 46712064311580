import React from "react";
import { graphql } from "gatsby";

import Seo from "../components/seo";
import { LocaleContext } from "../components/layout";
import useTranslations from "../components/useTranslations";
import Card from "../components/card";

export default function Discover({ data }) {
  const { locale, url } = React.useContext(LocaleContext);
  const allWorks = data.allMarkdownRemark.edges;

  const { discover } = useTranslations();

  return (
    <>
      <Seo
        url={url}
        lang={locale}
        title={discover}
        description={data.markdownRemark.frontmatter.metaDescription}
        bodyClass="page-discover"
      />
      <div className="container">
        <div className="cards">
          {allWorks.map((item) => (
            <Card
              key={item.node.fields.baseSlug}
              title={item.node.frontmatter.title}
              author={item.node.frontmatter.author}
              cover={item.node.frontmatter.cover}
              link={item.node.fields.baseSlug}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query ($locale: String!) {
    allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        frontmatter: { author: { ne: null } }
      }
      sort: { fields: [frontmatter___id], order: ASC }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            title
            author
            cover {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          fields {
            baseSlug
          }
        }
      }
    }
    markdownRemark(
      frontmatter: { template: { eq: "about" } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        metaDescription
      }
    }
  }
`;
