import React from "react";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { LocaleContext } from "../components/layout";
import LocalizedLink from "../components/localizedLink";

export default function Card({ title, cover, author, count, link }) {
  const image = getImage(cover);
  const { locale } = React.useContext(LocaleContext);

  return (
    <article className="card">
      <LocalizedLink className="card__link" to={link}>
        <BgImage image={image} className="card__cover aspect-ratio--16-9">
          <div className="background-overlay" />
          <h2
            lang={locale}
            className="card__title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h2>
        </BgImage>
        <div className="card__authors">{author}</div>
      </LocalizedLink>
    </article>
  );
}
